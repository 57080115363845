/* /* table {
    width: inherit;
    border: 1px solid #D3D7D9;
    border-collapse: separate;
    border-left: 0;
    border-radius: 5px;
    border-spacing: 0px;
} */

thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
    border-collapse: separate;
    background-color: #F7F7F7;
    border-radius: inherit;
}


/* tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}

th,
td {
    padding: 10px 20px 10px 20px;
    text-align: left;
    vertical-align: middle;
    border-left: 1px solid #D3D7D9;
}

th {
    text-align: center;
}

td {
    border-top: 1px solid #D3D7D9;
}

thead:first-child tr:first-child th:first-child,
tbody:first-child tr:first-child td:first-child {
    border-radius: 5px 0 0 0;
}

thead:last-child tr:last-child th:first-child,
tbody:last-child tr:last-child td:first-child {
    border-radius: 0 0 0 5px;
} */